import {
  FunctionComponent,
  Suspense,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableSortLabel,
  Skeleton,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import styles from "./layout/ProjectManagementUI.module.css";
import { useHistory } from "react-router";
import { ProductionListType } from "types/ProductionType";
import { MachineType } from "types/MachineType";
import { apiGetProductions } from "util/network/Productions";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import AppStateContext from "contexts/AppStateContext";
import { useQuery, useQueryClient } from "react-query";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { apiGetMachines } from "util/network/Machine";
import { ReactComponent as CommentIcon } from "../../assets/svg/commentOrder.svg";
import { ReactComponent as CommentIconFilled } from "../../assets/svg/commentOrderFilled.svg";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

const ProjectRow = lazy(() => import("./ProjectRow"));

export type SectionType = {
  all: boolean;
  notStart: boolean;
  review: boolean;
  cam: boolean;
  production: boolean;
  postprocess: boolean;
  shipping: boolean;
  done?: boolean;
  outsourced?: boolean;
  custom?: boolean;
};

type PropsType = {
  machinesIn?: MachineType[];
  selectedMachines: number[];
  selectedUsers: number[];
  section: SectionType;
  searchValue?: string;
  setRefreshFunc?: (func: () => void) => void;
  orderId?: number;
};

const ProjectTable: FunctionComponent<PropsType> = ({
  machinesIn,
  selectedMachines,
  selectedUsers,
  section,
  searchValue,
  setRefreshFunc,
  orderId,
}) => {
  const { token } = useContext(AppStateContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const [fetchTimeStamp, setFetchTimeStamp] = useState<number>(0);

  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>(
    15,
    "productionPageSize"
  );
  const [sortKey, setSortKey] = useStateWithLocalStorage<
    "deadline_date" | "order_id" | "priority"
  >("deadline_date", "productionSortKey");
  const [sortDirection, setSortDirection] = useStateWithLocalStorage<
    "asc" | "desc"
  >("asc", "productionSortDirection");

  const [machines, setMachines] = useState<MachineType[]>(machinesIn ?? []);

  const refresh = () => {
    queryClient.invalidateQueries("productions");
  };

  const [testUrl, setTestUrl] = useState<string>();

  // Init
  useEffect(() => {
    if (machinesIn) {
      setMachines(machinesIn);
    } else {
      apiGetMachines(token).then((res) => {
        setMachines(res);
      });
    }
    setRefreshFunc && setRefreshFunc(refresh);
  }, [queryClient, setRefreshFunc, machinesIn, token]);

  const getProductions = (
    page: number,
    pageSize: number,
    section: SectionType,
    sortKey: "deadline_date" | "order_id" | "priority",
    sortDirection: "asc" | "desc",
    selectedMachines: number[],
    selectedUsers: number[],
    search?: string,
    orderId?: number
  ) => {
    let filter = [];
    if (section.all) {
    } else {
      if (section.notStart) filter.push("todo=true");
      if (section.review) filter.push("review=ACTIVE");
      if (section.cam) filter.push("cam=ACTIVE");
      if (section.production) filter.push("production=ACTIVE");
      if (section.postprocess) filter.push("afterwork=ACTIVE");
      if (section.shipping) filter.push("shipping=ACTIVE");
      if (section.custom) filter.push("custom=ACTIVE");
    }
    if (section.done !== undefined) filter.push(`done=${section.done}`);
    if (section.outsourced !== undefined) {
      filter.push(`outsourced=${section.outsourced}`);
    }

    if (orderId) filter.push(`orderId=${orderId}`);
    if (selectedUsers.length > 0) {
      filter.push(`users=${selectedUsers.join(",")}`);
    }
    if (selectedMachines.length > 0) {
      filter.push(`machines=${selectedMachines.join(",")}`);
    }

    const filter_string = filter.join("&");
    setTestUrl(
      `page=${page}&pageSize=${pageSize}&${filter_string}&sortKey=${sortKey}&sortDirection=${sortDirection}&search=${search}`
    );
    return apiGetProductions(
      token,
      page + 1,
      pageSize,
      filter_string,
      sortKey,
      sortDirection,
      search
    ).then((res) => {
      setFetchTimeStamp(Date.now());
      return res;
    });
  };

  const { data, isError, error, isFetching, isLoading, isPreviousData } =
    useQuery<ProductionListType, any>(
      [
        "productions",
        page,
        pageSize,
        section,
        sortKey,
        sortDirection,
        selectedMachines,
        selectedUsers,
        searchValue,
        orderId,
      ],
      () =>
        getProductions(
          page,
          pageSize,
          section,
          sortKey,
          sortDirection,
          selectedMachines,
          selectedUsers,
          searchValue,
          orderId
        ),
      {
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 30 * 60 * 1000, // 30 minutes
        // getNextPageParam: (lastPage: ProductionListType) => {
        //   if (lastPage.pageSize * lastPage.page < lastPage.total)
        //     return lastPage.page + 1;
        //   return false;
        // },
        // getPreviousPageParam: (firstPage: ProductionListType) => {
        //   if (firstPage.page > 1) return firstPage.page - 1;
        //   return false;
        // },
      }
    );

  // Prefetch the next page!
  // useEffect(() => {
  //   if (data && data?.pageSize * data?.page < data?.total) {
  //     queryClient.prefetchQuery(
  //       [
  //         "productions",
  //         page + 1,
  //         pageSize,
  //         section,
  //         sortKey,
  //         sortDirection,
  //         selectedMachines,
  //         selectedUsers,
  //         searchValue,
  //         orderId,
  //       ],
  //       () =>
  //         getProductions(
  //           page + 1,
  //           pageSize,
  //           section,
  //           sortKey,
  //           sortDirection,
  //           selectedMachines,
  //           selectedUsers,
  //           searchValue,
  //           orderId
  //         )
  //     );
  //   }
  // }, [
  //   data,
  //   page,
  //   pageSize,
  //   section,
  //   sortKey,
  //   sortDirection,
  //   selectedMachines,
  //   selectedUsers,
  //   queryClient,
  //   searchValue,
  //   orderId,
  // ]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const reformatDate = (date: number | Date | string) => {
    let d = new Date(date);
    return d.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const projectMemo = useMemo(() => {
    let oldId = 0;
    const rows = data?.items?.map((project) => {
      if (
        project.orderId &&
        project.orderId !== oldId &&
        orderId === undefined
      ) {
        oldId = project.orderId;
        return (
          <>
            <TableRow
              key={`${project.orderId}`}
              className={styles.projectTitle}
            >
              <TableCell align="center" colSpan={6}>
                <div 
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    gap: "10px",
                    marginTop: "1.5em",
                  }}>
                  <a href={`/ordrestyring/${project.orderId}`}
                    style={{
                      textDecoration: "none",
                      fontSize: "0.9rem",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    {"#" + project.orderId.toString().padStart(4, "0")}
                  </a>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "0.2rem",
                        paddingRight: "0.5rem",
                      }}
                    >
                      {!project.orderMessage ? (
                        <CommentIcon />
                      ) : (
                        <LightTooltip title={project?.orderMessage}>
                          <CommentIconFilled />
                        </LightTooltip>
                      )}
                    </span>
                    <b style={{ fontSize: "0.9rem" }}>
                      {project.orderCompanyName}
                    </b>
                  </div>
                  <b>{reformatDate(project.orderDate)}</b>
                </div>
                
                
                
              </TableCell>
            </TableRow>
            <Suspense
              fallback={
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton variant="rectangular" height={55} />
                  </TableCell>
                </TableRow>
              }
            >
              <ProjectRow
                key={`${project.id}${project.modified}${fetchTimeStamp}`}
                project={project}
                machines={machines}
              />
            </Suspense>
          </>
        );
      } else
        return (
          <Suspense
            fallback={
              <TableRow>
                <TableCell colSpan={11}>
                  <Skeleton variant="rectangular" height={55} />
                </TableCell>
              </TableRow>
            }
          >
            <ProjectRow
              key={`${project.id}${project.modified}${fetchTimeStamp}`}
              project={project}
              machines={machines}
            />
          </Suspense>
        );
    });
    return rows;
  }, [data, machines, history]);

  return (
    <div>
      {debug && testUrl}
      {/* <p>{grouped ? "true" : "false"}</p> */}
      {isError && <div> {error?.message} </div>}
      {/* {isLoading || isFetching ? <LinearProgress /> : null} */}
      <TableContainer sx={{overflowX: 'unset'}}>
        {
          <Table padding={"checkbox"} size="small" aria-label="a dense table" style={{ tableLayout: 'auto' }}>
            <TableHead
              sx={{
                backgroundColor: "#075E5B",
                height: "40px",
              }}
            >
              <TableRow key={"header"}>
                <TableCell align="left" sx={{ backgroundColor: "var(--header-2)" }}>
                </TableCell>
                <TableCell
                  key={"quantity"}
                  align={"center"}
                  sx={{ backgroundColor: "var(--header-2)" }}
                >
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body2"}
                  >
                    ITEM
                  </Typography>
                </TableCell>
                <TableCell
                  align={"center"}
                  sx={{ backgroundColor: "var(--header-2)" }}
                >
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body2"}
                  >
                    DETAILS
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  key={"deadline"}
                  align={"center"}
                  sx={{ backgroundColor: "var(--header-2)" }}
                >
                  <TableSortLabel
                    sx={{ color: "white" }}
                    onClick={() => {
                      setSortKey("deadline_date");
                      setSortDirection((old: "asc" | "desc") =>
                        old === "asc" ? "desc" : "asc"
                      );
                    }}
                    active={sortKey === "deadline_date"}
                    direction={sortDirection}
                  >
                    <Typography
                      color={"white"}
                      fontWeight={"bold"}
                      variant={"body2"}
                    >
                      DEADLINE
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align={"left"}
                  sx={{ backgroundColor: "var(--header-2)" }}
                >
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body2"}
                  >
                    TASKS
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{projectMemo}</TableBody>
          </Table>
        }
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={debug ? [15, 30, 50, 100, 250, 1000] : [15, 30, 50]}
        component="div"
        count={data?.total ?? 0}
        rowsPerPage={data?.pageSize ?? pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // showFirstButton={true}
        // showLastButton={true}
        nextIconButtonProps={{
          disabled: isPreviousData,
        }}
      />
    </div>
  );
};

export default ProjectTable;
